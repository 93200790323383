import React, { createContext, useReducer, useState } from "react";
import { act } from "react-dom/test-utils";

// 5. The reducer - this is used to update the state, based on the action
export const AppReducer = (state, action) => {
  let budget = 0;
  switch (action.type) {
    case "ADD_EXPENSE":
      let total_budget = 0;
      total_budget = state.expenses.reduce((previousExp, currentExp) => {
        return previousExp + currentExp.cost;
      }, 0);
      total_budget = total_budget + action.payload.cost;
      action.type = "DONE";
      if (total_budget <= state.budget) {
        total_budget = 0;
        state.expenses.map((currentExp) => {
          if (currentExp.name === action.payload.name) {
            currentExp.cost = action.payload.cost + currentExp.cost;
          }
          return currentExp;
        });
        return {
          ...state,
        };
      } else {
        alert("Cannot increase the allocation! Out of funds");
        return {
          ...state,
        };
      }

    case "DECREASE_EXPENSE":
      let total_budget_d = 0;
      total_budget_d = state.expenses.reduce((previousExp_d, currentExp_d) => {
        return previousExp_d + currentExp_d.cost;
      }, 0);

      total_budget_d = total_budget_d + action.payload.cost;
      // console.log(total_budget_d, state.budget)
      action.type = "DONE";
      if (total_budget_d <= state.budget) {
        total_budget_d = 0;
        state.expenses.map((currentExp_d) => {
          if (currentExp_d.name === action.payload.name) {
            if (currentExp_d.cost > 0) {
              currentExp_d.cost = currentExp_d.cost - action.payload.cost;
            } else {
              alert(
                "Cannot decrease the allocation for " +
                  currentExp_d.name +
                  " Department"
              );
            }
          }
          return currentExp_d;
        });
        return {
          ...state,
        };
      } else {
        alert("Cannot decrease the allocation! Out of funds");
        return {
          ...state,
        };
      }
    case "RED_EXPENSE":
      const red_expenses = state.expenses.map((currentExp) => {
        if (
          currentExp.name === action.payload.name &&
          currentExp.cost - action.payload.cost >= 0
        ) {
          currentExp.cost = currentExp.cost - action.payload.cost;
          budget = state.budget + action.payload.cost;
        }
        return currentExp;
      });
      action.type = "DONE";
      return {
        ...state,
        expenses: [...red_expenses],
      };
    case "DELETE_EXPENSE":
      action.type = "DONE";
      state.expenses.map((currentExp) => {
        if (currentExp.name === action.payload) {
          budget = state.budget + currentExp.cost;
          currentExp.cost = 0;
        }
        return currentExp;
      });
      action.type = "DONE";
      return {
        ...state,
        budget,
      };
    case "SET_BUDGET":
      action.type = "DONE";
      state.budget = action.payload;

      return {
        ...state,
      };
    case "CHG_CURRENCY":
      action.type = "DONE";
      state.currency = action.payload;
      // console.log(action.payload.symbol);
      return {
        ...state,
      };

    default:
      return state;
  }
};

// 1. Sets the initial state when the app loads
const initialState = {
  budget: 2000,
  expenses: [
    { id: "Marketing", name: "Marketing", cost: 50 },
    { id: "Finance", name: "Finance", cost: 300 },
    { id: "Sales", name: "Sales", cost: 70 },
    { id: "Human Resource", name: "Human Resource", cost: 40 },
    { id: "IT", name: "IT", cost: 500 },
  ],
  currency: [
    { id: 0, symbol: "$", name: "Dollar" },
    { id: 1, symbol: "£", name: "Pound" },
    { id: 2, symbol: "€", name: "Euro" },
    { id: 3, symbol: "₹", name: "Rupee" },
  ],
  initialCurrency: "$",
};

// 2. Creates the context this is the thing our components import and use to get the state
export const AppContext = createContext();

// 3. Provider component - wraps the components we want to give access to the state
// Accepts the children, which are the nested(wrapped) components
export const AppProvider = (props) => {
  // 4. Sets up the app state. takes a reducer, and an initial state
  const [state, dispatch] = useReducer(AppReducer, initialState);

  let remaining = 0;

  if (state.expenses) {
    const totalExpenses = state.expenses.reduce((total, item) => {
      return (total = total + item.cost);
    }, 0);
    remaining = state.budget - totalExpenses;
  }

  return (
    <AppContext.Provider
      value={{
        expenses: state.expenses,
        budget: state.budget,
        remaining: remaining,
        dispatch,
        currency: state.currency,
        initialCurrency: state.initialCurrency,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
