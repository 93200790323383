import React, { useState, useContext } from "react";
import { AppContext } from "../context/AppContext";

const CurrencyList = () => {
  const { currency, dispatch } = useContext(AppContext);

  const [item, setItem] = useState(currency);
  const [selected, setSelected] = useState();
  const [open, setOpen] = useState(false);

  const handleChangeCurrency = (name, symbol) => {
    if (name !== selected) {
      setSelected({ name: name, symbol: symbol });
      setOpen(false);
    }
    const newCurrency = {
      name: name,
      symbol: symbol,
    };
    // console.log(newCurrency);
    dispatch({
      type: "CHG_CURRENCY",
      payload: newCurrency,
    });
  };

  return (
    <div className="dropdown currency-selector" style={{ width: "100%" }}>
      <button
        className="dropdown-toggle btn btn-secondary"
        onClick={() => setOpen(!open)}
      >
        {selected
          ? "Currency (" + selected.symbol + " " + selected.name + ")"
          : "Currency (" + item[0].symbol + " " + item[0].name + ")"}
      </button>
      <ul className={`dropdown-menu ${open && "show"}`}>
        {item.map((item) => (
          <li
            key={item.id}
            onClick={() => handleChangeCurrency(item.name, item.symbol)}
            className="dropdown-item"
            style={{ cursor: "pointer" }}
          >
            {item.symbol}
            <span className="mx-2">{item.name}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CurrencyList;
