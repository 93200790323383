import React, { useContext, useState } from "react";
import { AppContext } from "../context/AppContext";

const Budget = () => {
  const { budget, dispatch, expenses, currency, initialCurrency } = useContext(AppContext);
  const [value, setValue] = useState(budget);
  // console.log(currency)
  const totalExpenses = expenses.reduce((total, item) => {
    return (total += item.cost);
  }, 0);

  const handleChangeNumber = (value) => {
    if (value <= totalExpenses) {
      alert("You can not reduce the budget value lower than the spending");
    } else if(value > 20000) {
      alert("Budget should not be above £20000")
    } else {
      dispatch({
        type: "SET_BUDGET",
        payload: value,
      });
    }
  };

  return (
    <div className="alert alert-secondary d-flex align-item-center justify-content-between">
      <div>Budget:</div>
      <div>
        <span className="mx-2" style={{fontWeight: "bold", color: "black"}}>{currency.symbol ? currency.symbol : initialCurrency }</span>
        <input
          required="required"
          type="number"
          id="budget_value"
          value={value}
          step="10"
          onChange={(event) => setValue(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleChangeNumber(value);
            }
          }}
        />
      </div>
    </div>
  );
};

export default Budget;
